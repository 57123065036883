@container yse7za_card-content-container (width < 224px) {
  .yse7za_customCardFieldList.yse7za_customCardFieldList {
    font-size: 12px;
  }

  img {
    max-width: 100%;
  }

  .yse7za_avatarContainer.yse7za_avatarContainer {
    width: 24px;
    height: 24px;
  }

  .yse7za_summary.yse7za_summary {
    text-overflow: ellipsis;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    font-size: 14px;
    display: -webkit-box;
    overflow: hidden;
  }

  .yse7za_cardColorInner.yse7za_cardColorInner {
    width: 2px;
  }

  .yse7za_footer.yse7za_footer {
    grid-template: "yse7za_tertiary yse7za_tertiary" 1fr
                   "yse7za_primary yse7za_secondary" 1fr
                   / minmax(0, 1fr) auto;
  }

  .yse7za_content.yse7za_content {
    padding: 8px;
  }

  .yse7za_footerChildSection.yse7za_footerChildSection {
    gap: 4px;
  }

  .yse7za_cmpAvatar.yse7za_cmpAvatar {
    width: 24px;
    height: 24px;
  }

  .yse7za_iconsPlaceHolder.yse7za_iconsPlaceHolder {
    width: 100%;
    height: 24px;
    display: block;
  }
}

.j-BFpW_compactLozenge.j-BFpW_compactLozenge {
  display: none;
}

@container j-BFpW_column-header (width < 275px) {
  .j-BFpW_compactLozenge.j-BFpW_compactLozenge {
    display: inline;
  }

  .j-BFpW_defaultLozenge.j-BFpW_defaultLozenge, .j-BFpW_defaultLozengeContainer.j-BFpW_defaultLozengeContainer {
    display: none;
  }

  .j-BFpW_compactColumnTitle.j-BFpW_compactColumnTitle {
    flex: 1;
    margin: 0;
  }
}
/*# sourceMappingURL=shared.f8390f20.css.map */
